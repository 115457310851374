export function formatDateFromBack(date:any){
  let arr = date.split('-');
  return new Date(+arr[0],+arr[1]-1,+arr[2]);
}

export function formatDateToBack(date:any){
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

export function compareDate(deadline:any){
  let date1 = formatDateFromBack(deadline).getTime();
  let date2 = new Date().setHours(0,0,0,0);
  return (date2-date1)>0


}

export function GetTagsListFromArray(array:any){
  const arr = [];
  for(const item of array){
    arr.push(...item['tags']||[])
  }
  return [...new Set(arr)]



}


export const textEditorConfig = {
  toolbar: [
    'heading', '|',
    'fontfamily', 'fontsize','fontColor','fontBackgroundColor',
    'alignment', '|',
    'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
    'link',
    'bulletedList', 'numberedList', '|',
    'insertTable', '|',
    'undo', 'redo',
    'removeFormat'
  ],

  heading: {
    options: [
      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
      { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
      { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
      { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
      { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
      { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' }
    ]
  },
  fontSize:{
    options:
      Array.from({length: 65}, (_, i) => i + 8),

  },
  image: {
    // Configure the available styles.
    styles: [
      //'alignBlockLeft', 'alignCenter', 'alignBlockRight'
    ],

    // Configure the available image resize options.
    resizeOptions: [
      {
        name: 'resizeImage:original',
        label: 'Original',
        value: null
      },
      {
        name: 'resizeImage:50',
        label: '25%',
        value: '25'
      },
      {
        name: 'resizeImage:50',
        label: '50%',
        value: '50'
      },
      {
        name: 'resizeImage:75',
        label: '75%',
        value: '75'
      }
    ],

    // You need to configure the image toolbar, too, so it shows the new style
    // buttons as well as the resize buttons.
    toolbar: [
      'imageStyle:alignBlockLeft', 'imageStyle:alignCenter', 'imageStyle:alignBlockRight',
      '|',
      'ImageResize',
      '|',
      'imageTextAlternative'
    ]
  },
  table: {
    tableCellProperties: {
      defaultProperties: {
        verticalAlignment: 'center',
      }
    }
  },
  link: {
    decorators: {
      addTargetToExternalLinks: {
        mode: 'automatic',
        callback: (url:string) => /^(https?:)?\/\//.test( url ),
        attributes: {
          target: '_blank',
          rel: 'noopener noreferrer'
        }
      }
    }
  },

  language: 'ru'
};


