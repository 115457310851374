import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import { tap } from 'rxjs';
import { EducationService } from 'src/app/shared/services/education.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import * as Editor from 'ckeditor5/build/ckeditor';
import {NgIf} from "@angular/common";
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import {FormsModule} from "@angular/forms";
import {SafeHtml} from "../../../../../shared/pipes/SafeHtml";
import {LyDialog} from "@alyle/ui/dialog";
import {LyOverlay} from "@alyle/ui";
import {textEditorConfig} from "../../../../../shared/helpers/helpers";


@Component({
  selector: 'app-lesson-content-item-text',
  templateUrl: './lesson-content-item-text.component.html',
  styleUrls: ['./lesson-content-item-text.component.scss'],
  imports: [
    NgIf,
    CKEditorModule,
    FormsModule,
    SafeHtml
  ],
  standalone: true,
  providers:[
    {provide:LyDialog},
    {provide:LyOverlay},

  ]
})
export class LessonContentItemTextComponent implements OnInit {


  public Editor1:any = Editor;
  config:any = textEditorConfig;

  @Input() ItemUid: string = '';
  @Input() ItemData: any = null;
  @Input() IsSelected = false;


  @Output() OnChanged = new EventEmitter<any>();

  InitText: any = null;

  constructor(
    public loadingService: LoadingService,
    private educationService: EducationService,

  ) {

  }

  ngOnInit(): void {
    this.InitText = this.ItemData;

  }

  get hasChanged() { return this.InitText != this.ItemData };

  changeText() {
    this.ChangeItem().subscribe()
  }

  cancelChangeText () {
    this.ItemData = this.InitText;
    this.OnChanged.emit(this.ItemData)
  }

  ChangeItem() {
    const form = new FormData();
    form.append('content', String(this.ItemData));

    return this.educationService.EditLessonContentData(this.ItemUid, form)
        .pipe(
          tap((res) => {
            this.InitText = this.ItemData;
            this.OnChanged.emit(this.ItemData)
          })
    )
  }

  ResetFocus(e:any){
    //console.log("ResetFocus",e)

  }


}
