import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import * as Editor from 'ckeditor5/build/ckeditor';
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import {FormsModule} from "@angular/forms";
import {LoadingService} from "../../../../../shared/services/loading.service";
import {EventsService} from "../../../../../shared/services/events.service";
import {PreloaderComponent} from "../../../../../shared/ui/preloader/preloader.component";
import {NgIf} from "@angular/common";
import {ConstantsService} from "../../../../../shared/services/constants.service";
import {textEditorConfig} from "../../../../../shared/helpers/helpers";

@Component({
  selector: 'app-open-test',
  templateUrl: './open-test.component.html',
  styleUrls: ['./open-test.component.scss'],
  imports: [
    CKEditorModule,
    FormsModule,
    PreloaderComponent,
    NgIf
  ],
  standalone: true
})
export class OpenTestComponent {

  constantsService = inject(ConstantsService);
  eventsService = inject(EventsService);
  protected loadingService = inject(LoadingService)

  public Editor1:any = Editor;
  config:any = textEditorConfig;

  @Input() id = 0;
  @Input() question = '';
  @Input() ErrorText = false;


  @Output() OnChangeQuestionText = new EventEmitter();
  @Output() OnDeleteQuestion = new EventEmitter();


  @Input() EmptyQuestionList:any = [];

  @Input() FileId = null;
  @Input() FileName:any = null;
  @Input() DownloadId:any = null;
  @Output() OnUploadFile = new EventEmitter();
  @Output() OnDelete = new EventEmitter();
  @Output() OnDownload = new EventEmitter();


  ChangeQuestionText(){
    this.OnChangeQuestionText.emit(
        {
          id: this.id,
          question: this.question
        }
    )
  }

  DeleteTest(){
    this.OnDeleteQuestion.emit({id:this.id})
  }

  DeleteFile(){
    this.OnDelete.emit();
  }

  UploadFile(files: any){
    const file = files.files.item(0);
    let size:number = +(file['size'])
    if(size<=this.constantsService.FileMaxSize) {
      this.OnUploadFile.emit(file)
    }else{
      this.eventsService.throwError(`Размер файла не должен превышать ${this.constantsService.FileMaxSizeText}!`)
    }
  }

  DownloadFile(){
    this.OnDownload.emit({
      fileId: this.FileId,
      fileName: this.FileName
    });



  }



    protected readonly Editor = Editor;
}
