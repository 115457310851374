import {Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import * as Editor from "ckeditor5-custom-build";
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {PreloaderComponent} from "../../../../../shared/ui/preloader/preloader.component";
import {ConstantsService} from "../../../../../shared/services/constants.service";
import {EventsService} from "../../../../../shared/services/events.service";
import {LoadingService} from "../../../../../shared/services/loading.service";
import {textEditorConfig} from "../../../../../shared/helpers/helpers";

@Component({
  selector: 'app-close-test',
  templateUrl: './close-test.component.html',
  styleUrls: ['./close-test.component.scss'],
  imports: [
    CKEditorModule,
    PreloaderComponent,
    NgIf,
    NgClass,
    FormsModule,
    NgForOf
  ],
  standalone: true
})
export class CloseTestComponent implements OnInit {

  constantsService = inject(ConstantsService);
  eventsService = inject(EventsService);
  protected loadingService = inject(LoadingService)


  @Input() id = 0;
  @Input() maxId = 0;
  @Input() question = '';
  @Input() answers: any[] = [];
  @Input() EmptyQuestionList: any = [];
  @Input() ErrorText = false;


  @Output() OnAddVariant = new EventEmitter();
  @Output() OnChangeVariant = new EventEmitter();
  @Output() OnChangeQuestionText = new EventEmitter();
  @Output() OnDeleteQuestion = new EventEmitter();
  @Output() OnDeleteVariant = new EventEmitter();

  @Input() FileId = null;
  @Input() FileName: any = null;
  @Input() DownloadId: any = 0;

  @Output() OnUploadFile = new EventEmitter();
  @Output() OnDelete = new EventEmitter();
  @Output() OnDownload = new EventEmitter();

  maxVariantId = 0;
  public Editor1: any = Editor;
  config: any = textEditorConfig;


  ChangeQuestionText() {
    this.OnChangeQuestionText.emit(
      {
        id: this.id,
        question: this.question
      }
    )
  }

  DeleteTest() {
    this.OnDeleteQuestion.emit({id: this.id})
  }

  DeleteVariant(variantId: number) {
    this.OnDeleteVariant.emit(
      {
        id: this.id, variantId
      }
    )
  }

  AddVariant() {
    const id = this.maxId + 1;
    this.OnAddVariant.emit({
      id: this.id,
      variantId: id,
      right: this.answers.length < 1,
      error: false
    })

  }


  ChangeRightAnswer(item: any, event: any) {
    item['right'] = !item['right'];
    this.ChangeAnswers(item);
  }

  ChangeAnswers(item: any) {
    item['error'] = item['text'] === '';
    this.OnChangeVariant.emit({
      id: this.id,
      item
    })


  }

  DeleteFile() {
    this.OnDelete.emit();
  }

  UploadFile(files: any) {
    const file = files.files.item(0);
    let size: number = +(file['size'])
    if (size <= this.constantsService.FileMaxSize) {
      this.OnUploadFile.emit(file)
    } else {
      this.eventsService.throwError(`Размер файла не должен превышать ${this.constantsService.FileMaxSizeText}!`)
    }
  }

  DownloadFile() {
    this.OnDownload.emit({
      fileId: this.FileId,
      fileName: this.FileName
    });
  }

  ngOnInit(): void {
  }

  protected readonly Editor = Editor;

}
